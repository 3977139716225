import { template as template_ae8ca49f87a5479c9849dd688da29a77 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ae8ca49f87a5479c9849dd688da29a77(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
