import { template as template_338536f7fbf34261ba77a4e87c3210f5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_338536f7fbf34261ba77a4e87c3210f5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
