import { template as template_80380c7a2f3d402eb461a38b0fad5013 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import { i18n } from "discourse-i18n";
const UserSummarySection = template_80380c7a2f3d402eb461a38b0fad5013(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
